import { useIsRouting, useNavigate } from "@solidjs/router";
import {
  Accessor,
  createContext,
  createEffect,
  createSignal,
  onCleanup,
} from "solid-js";

export type TransitionState = "none" | "start" | "loading" | "end";

export type TransitionContextType = {
  startTransition: (to: string) => void;
  nextPath: Accessor<string>;
  transitionState: Accessor<TransitionState>;
};

export function useProviderValue() {
  const isRouting = useIsRouting();
  const navigate = useNavigate();
  const [transitionState, setTransitionState] =
    createSignal<TransitionState>("none");
  const [nextPath, setNextPath] = createSignal("");
  let timeoutId: string | number | NodeJS.Timeout | undefined;

  const startTransition = (to: string) => {
    setNextPath(to);
    setTransitionState("start");
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      setTransitionState("loading");
      navigate(to);
    }, 50);
  };

  createEffect(() => {
    if (!isRouting() && transitionState() !== "none") {
      setTransitionState("end");
      timeoutId = setTimeout(() => setTransitionState("none"), 50);
    }
  });

  onCleanup(() => {
    clearTimeout(timeoutId);
  });

  const contextValue: TransitionContextType = {
    startTransition,
    nextPath,
    transitionState,
  };
  return contextValue;
}

export type ContextType = ReturnType<typeof useProviderValue>;

const TransitionContext = createContext<ContextType | undefined>();

export default TransitionContext;
